export default [
    {
        name: 'required',
        validator: {
            //Extend with checking if value is empty object
            validate: function (value, ref) {
                /** @source node_modules/vee-validate/dist/vee-validate.minimal.js:isEmptyArray **/
                function isEmptyArray(arr) {
                    return Array.isArray(arr) && arr.length === 0;
                }

                /** @source node_modules/vee-validate/dist/vee-validate.minimal.js:isNullOrUndefined **/
                function isNullOrUndefined() {
                    var values = [], len = arguments.length;
                    while ( len-- ) values[ len ] = arguments[ len ];

                    return values.every(function (value) {
                        return value === null || value === undefined;
                    });
                }

                //Custom function
                function isEmptyObject(value) {
                    var ownProperties = Object.getOwnPropertyNames(value);
                    return ((ownProperties.length === 0 || (ownProperties.length === 1 && ownProperties[0] === '__ob__')) && Object.getPrototypeOf(value) === Object.prototype);
                }

                if ( ref === void 0 ) ref = [];
                var invalidateFalse = ref[0]; if ( invalidateFalse === void 0 ) invalidateFalse = false;

                if (isNullOrUndefined(value) || isEmptyArray(value) || isEmptyObject(value)) {
                    return false;
                }

                // incase a field considers `false` as an empty value like checkboxes.
                if (value === false && invalidateFalse) {
                    return false;
                }

                return !!String(value).trim().length;
            }
        }
    },
    {
        name: 'alpha_name',
        validator: {
            /*
            Extend alpha dash rule
            - remove numeric
            - remove underscore
            - add space
             */
            validate: function (value, ref) {
                var alphaName = {
                    en: /^[A-Z -]*$/i,
                    nl: /^[A-ZÉËÏÓÖÜ -]*$/i,
                };

                if ( ref === void 0 ) ref = {};
                var locale = ref.locale;

                if (Array.isArray(value)) {
                    return value.every(function (val) { return validate(val, [locale]); });
                }

                // Match at least one locale.
                if (! locale) {
                    return Object.keys(alphaName).some(function (loc) { return alphaName[loc].test(value); });
                }

                return (alphaName[locale] || alphaName.en).test(value);
            }
        }
    },
    {
        name: 'is_time',
        validator: {
            validate: function (value, ref) {
                var regex = new RegExp("([0-1][0-9]|2[0-3]):([0-5][0-9])");
                if (value && regex.test(value)) {
                    return true;
                } else {
                    return false
                }
            }
        }
    },
    {
        name: 'before_time',
        validator: {
            validate: function (value, [otherValue]) {
                function toMinutes(time_str) {
                    var parts = time_str.split(':');
                    return parts[0] * 60 +  parts[1]

                }
                var difference = toMinutes(value) - toMinutes(otherValue);
                if (difference >= 0 ){
                    return true;
                }
                else {
                    return false;
                }
            },
            options: {
                hasTarget: true
            }
        }
    },
]
