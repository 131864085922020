// axios
import axios from 'axios'

const domain = "loc.crm.onderwijsmaakjesamen.nl"
const csrfToken = document.querySelector("meta[name=csrf-token]").content

export default axios.create({
    domain,
    headers: {
        common: {
            'domain': domain,
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': csrfToken,
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
    }
    // You can add your headers here
})
