import i18n from "../../../i18n/i18n";
export default {
  pages: {
    key: "title",
    data: [
      //   {title: i18n.t('Taken'), url: '/tasks', icon: 'CheckSquareIcon', is_bookmarked: true},
      // {title: 'Home',   url: '/',      icon: 'HomeIcon', is_bookmarked: false},
      // {title: 'Page 2', url: '/page2', icon: 'FileIcon', is_bookmarked: false},
      //   {title: 'Tasks', route: {name: 'tasks'}, icon: 'CheckSquareIcon', is_bookmarked: true},
    ]
  }
}
