/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import Router from 'vue-router'
import i18n from "./i18n/i18n";
import { subject } from '@casl/ability';
import store from './store/store'
Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (to.path === from.path) {
            return savedPosition;
        }
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            redirect: '/login'
            //todo when not authenticated
        },
        {
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            children: [
                //dashboard routes
                {
                    path: '/academy',
                    name: 'academy statistics',
                    component: () => import('./components/dashboard/academy/index'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: i18n.t('Dashboard'), url: null},
                            { title: i18n.t('Academy statistics'), active: true },
                        ],
                        parent: "dashboard", //todo when parent page should be set
                        pageTitle: '', //is loaded dynamically on component itself
                        authRequired: true, // todo check if authentication is working when set to true
                        // rule: 'editor' //todo authentication
                    },
                },
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/attendees',
                    name: 'attendees',
                    component: () => import('./views/Attendees'),
                    meta: {
                        authRequired: true
                    }
                },
                {
                    path: '/attendee/details/:id',
                    name: 'attendee view',
                    component: () => import('./views/Attendee'),
                    props: true,
                    meta: {
                        authRequired: true
                    }
                },

                //event routes
                {
                    path: '/events',
                    name: 'events',
                    component: () => import('./views/Events'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: i18n.t('Events'), active: true },
                        ],
                        endpoint: '/api/events',
                        // parent: "ecommerce-item-detail-view", //todo when parent page should be set
                        pageTitle: i18n.t('Events'),
                        // rule: 'editor' //todo authentication
                        authRequired: true
                    },
                    props: true
                },

                {
                    path: '/eventplanner',
                    name: 'event plan-tool',
                    component: () => import('./views/EventPlanTool'),
                    meta: {
                        authRequired: true
                    },
                },

                {
                    path: '/event/create',
                    name: 'create event',
                    component: () => import('./components/events/CreateEvent'),
                    meta: {
                        authRequired: true
                    },
                },

                {
                    path: '/event/edit/:id',
                    name: 'edit event',
                    component: () => import('./components/events/CreateEvent'),
                    meta: {
                        authRequired: true
                    },
                },

                {
                    path: '/event/details/:id',
                    name: 'event details',
                    component: () => import('./views/EventDetails'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: i18n.t('Events'), url:'/events/'},
                            { title: i18n.t('Details'), active: true },
                        ],
                        parent: "events", //todo when parent page should be set
                        pageTitle: '', //is loaded dynamically on component itself
                        authRequired: true // todo check if authentication is working when set to true
                        // rule: 'editor' //todo authentication
                    },
                },

                //schedule routes
                {
                    path: '/sessions/',
                    name: 'schedule',
                    component: () => import('./views/Sessions'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: i18n.t('Schedule'), active: true },
                        ],
                        endpoint: '/api/schedule',
                        // parent: "ecommerce-item-detail-view", //todo when parent page should be set
                        pageTitle: i18n.t('Schedule'),
                        // rule: 'editor' //todo authentication
                        authRequired: true
                    },
                    props: {}
                },
                {
                    path: '/session/details/:id',
                    name: 'session details',
                    component: () => import('./components/session/SessionDetails'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: i18n.t('Schedule'), url:'/sessions/'},
                            { title: i18n.t('Details'), active: true },
                        ],
                        parent: "sessions", //todo when parent page should be set
                        pageTitle: '', //is loaded dynamically on component itself
                        authRequired: true // todo check if authentication is working when set to true
                        // rule: 'editor' //todo authentication
                    },
                },
                {
                    path: '/sessions/archive',
                    name: 'session archive',
                    component: () => import('./views/SessionsArchive'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: i18n.t('Schedule'), url:'/sessions/'},
                            { title: i18n.t('Archive'), active: true },
                        ],
                        endpoint: '/api/schedule/archive',
                        pageTitle: i18n.t('Schedule archive'),
                        authRequired: true
                        // rule: 'editor' //todo authentication
                    },
                    props: {}
                },

                //tasks routes
                {
                    path: '/tasks',
                    name: 'tasks',
                    component: () => import('./views/Tasks'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: i18n.t('Tasks'), active: true },
                        ],
                        // parent: "ecommerce-item-detail-view", //todo when parent page should be set
                        pageTitle: i18n.t('Tasks'),
                        authRequired: true
                        // rule: 'editor' //todo authentication
                    },
                },
                {
                    path: '/welcome',
                    name: 'welcome',
                    component: () => import('./components/dashboard/Welcome'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: i18n.t('Welkom'), active: true },
                        ],
                        authRequired: false
                    }
                },
                {
                    path: '/profile/:tab?',
                    name: 'profile',
                    component: () => import('./views/Profile'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/'},
                            { title: i18n.t('Profile'), active: true }
                        ]
                    }
                },

                //KLIJFlab routes
                {
                    path: '/klijflab-events',
                    name: 'klijflab-events',
                    component: () => import('./views/Events'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'KLIJFlab'},
                            { title: i18n.t('Events'), active: true },
                        ],
                        endpoint: '/api/events/klijflab',
                        // parent: "ecommerce-item-detail-view", //todo when parent page should be set
                        pageTitle: i18n.t('Events'),
                        // rule: 'editor' //todo authentication
                        authRequired: true
                    },
                    props: true
                },
                {
                    path: '/klijflab-sessions/',
                    name: 'klijflab-schedule',
                    component: () => import('./views/Sessions'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'KLIJFlab'},
                            { title: i18n.t('Schedule'), active: true },
                        ],
                        endpoint: '/api/schedule/klijflab',
                        // parent: "ecommerce-item-detail-view", //todo when parent page should be set
                        pageTitle: i18n.t('Schedule'),
                        // rule: 'editor' //todo authentication
                        authRequired: true
                    },
                    props: {}
                },
                {
                    path: '/klijflab-sessions/archive',
                    name: 'klijflab-session-archive',
                    component: () => import('./views/SessionsArchive'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'KLIJFlab'},
                            { title: i18n.t('Schedule'), url:'/klijflab-sessions/'},
                            { title: i18n.t('Archive'), active: true },
                        ],
                        endpoint: '/api/schedule/archive/klijflab',
                        pageTitle: i18n.t('Schedule archive'),
                        authRequired: true
                        // rule: 'editor' //todo authentication
                    },
                    props: {}
                },
                {
                    path: '/klijflab-registrations',
                    name: 'klijflab-registrations',
                    component: () => import('./views/StatisticsKlijflab'),
                    meta: {
                        breadcrumb: [
                            { title: 'Home', url: '/' },
                            { title: 'KLIJFlab'},
                            { title: i18n.t('Registrations'), active: true },
                        ],
                        pageTitle: i18n.t('KLIJFlab registrations'),
                        authRequired: true
                    }
                },
                {
                    path: '/agenda/academie',
                    name: 'agenda-academy',
                    component: () => import('./components/agenda/Academie'),
                    meta: {
                        authRequired: true
                    }
                },
                {
                    path: '/agenda/all',
                    name: 'agenda-all',
                    component: () => import('./components/agenda/All'),
                    meta: {
                        authRequired: true
                    }
                },
            ],
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                {
                    path: '/login',
                    name: 'page-login',
                    component: () => import('@/views/Login.vue'),
                    meta: {
                        authRequired: false
                    }
                },
                {
                    path: '/logout',
                    name: 'page-logout',
                    component: () => import('@/views/Logout.vue'),
                    meta: {
                        authRequired: false
                    }
                },
                {
                    path: '/register',
                    name: 'page-register',
                    component: () => import('@/views/Register.vue'),
                    meta: {
                        authRequired: false
                    }
                },
                {
                    path: '/verificate/email',
                    name: 'page-verificate-email',
                    component: () => import('@/views/VerificateEmail.vue'),
                    meta: {
                        authRequired: false
                    }
                },
                {
                    path: '/password/forgot',
                    name: 'page-forgot-password',
                    component: () => import('@/views/ForgotPassword.vue'),
                    meta: {
                        authRequired: false
                    }
                },
                {
                    path: '/password/reset/:token?/:email?',
                    name: 'page-reset-password',
                    component: () => import('@/views/ResetPassword.vue'),
                    meta: {
                        authRequired: false
                    }
                },
                {
                    path: '/pages/error-404',
                    name: 'page-error-404',
                    component: () => import('@/views/pages/Error404.vue'),
                    meta: {
                        authRequired: false
                    }
                },
                {
                    path: '/email/verify/:id?/:sign?',
                    name: 'page-email-verify',
                    component: () => import('@/views/Verify.vue'),
                    meta: {
                        authRequired: false
                    }
                },
                {
                    path: '/email/change/:id?/:sign?',
                    name: 'page-email-verify-change',
                    component: () => import('@/views/VerifyChange.vue'),
                    meta: {
                        authRequired: false
                    }
                },

                // {
                //     path: '/terms',
                //     name: 'terms-and-conditions',
                //     component: () => import('./views/TermsAndConditions'),
                //     meta: {
                //         breadcrumb: [
                //             { title: 'Home', url: '/' },
                //             { title: i18n.t('terms and conditions'), active: true },
                //         ],
                //         authRequired: false
                //     }
                // },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ],
})

router.beforeEach(async (to, from, next) => {
    let $root = router.app;

    /**
     * Check if a logged in user is found in the state.
     * If not, then either no user is logged in or the page was (re)loaded.
     * Try to get logged in user from local storage to state
     */
    if (!store.getters.getLoggedInUser) {
        await store.dispatch('init');
    }

    //Check again if a logged in user was found
    if (store.getters.getLoggedInUser) {

        if (!store.state.AppActiveUser.location) {
            if (to.name !== 'page-logout' && (to.name !== 'profile' || (to.name === 'profile' && to.params.hasOwnProperty('tab') && to.params.tab !== 'organization'))) {
                $root.$vs.notify({
                    title: i18n.t('Profile update required'),
                    text: i18n.t('Please fill in your organization to continue'),
                    color: 'warning'
                })
                next({name: 'profile', params: {tab: 'organization'}});
                return;
            } else {
                next();
                return;
            }
        }

        //Check if the route requires authentication
        if ((to.meta.hasOwnProperty('authRequired') && to.meta.authRequired)) {
            //Check if user has permission to navigate to route, otherwise redirect to welcome
            if (store.getters.ability.can('do', subject('navigation', Object.assign({}, to)))) {
                next();
            } else {
                $root.$vs.notify({
                    title: i18n.t('No access'),
                    text: i18n.t('You do not have permission to view that page'),
                    color: 'danger'
                })
                next({name: 'welcome'});
            }
        } else {
            //Logged in users get a redirect on the login page
            if (to.name !== 'page-login') {
                next();
            } else {
                next({name: 'welcome'});
            }
        }
    } else {
        //Not logged in, redirect to login page
        if (to.name ===  'page-login' || to.name ===  'page-verificate-email' || to.name ===  'agenda' || to.name === 'page-forgot-password' || to.name === 'page-register' || to.name === 'page-reset-password' || to.name === 'page-email-verify' || to.name === 'terms-and-conditions') {
            next();
        } else {
            $root.$vs.notify({
                title: i18n.t('Not logged in'),
                text: i18n.t('Please login to continue'),
                color: 'danger'
            })
            next({name: 'page-login'});
        }
    }
});

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router
