import firebase from 'firebase/app'

var firebaseConfig = {
    apiKey: "AIzaSyAKZDZu5mb30nz84YhIgMwyvm0wgZn5oRU",
    authDomain: "omjs-crm-lokaal.firebaseapp.com",
    databaseURL: "https://omjs-crm-lokaal.firebaseio.com",
    projectId: "omjs-crm-lokaal",
    storageBucket: "omjs-crm-lokaal.appspot.com",
    messagingSenderId: "739811263356",
    appId: "1:739811263356:web:92107acd54b972d1899af9"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
