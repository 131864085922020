/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import App from "./App.vue";

// Vuesax Component Framework
import Vuesax from "vuesax";

Vue.use(Vuesax);

// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;
window.axios = axios; //todo checken

// Theme Configurations
import "../themeConfig.js";

// Globally Registered Components
import "./globalComponents.js";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// Firebase
import "@/firebase/firebaseConfig";

// i18n
import i18n from "./i18n/i18n";

// vee-validate
import { vv_locale_nl_extended } from "./validation/extend";
import { extendValidatorRules } from "./validation/extend";
import extendedRules from "./validation/rules";
import VeeValidate from "vee-validate";

extendValidatorRules(extendedRules);

Vue.use(VeeValidate, {
    locale: "nl",
    dictionary: {
        nl: vv_locale_nl_extended
    },
    events: "blur",
    inject: false,
    fastExit: false
});

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

//filters
import "./store/filters.js";

import { abilitiesPlugin } from "@casl/vue";
Vue.use(abilitiesPlugin, store.getters.ability);

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

import { extendNotify } from "./plugins/extendNotify";
Vue.use(extendNotify);

Vue.config.productionTip = false;

Vue.config.productionTip = false;
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");
