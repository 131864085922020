export default (store) => {
    const ability = store.getters.ability

    ability.update(store.state.rules)

    return store.subscribe((mutation) => {
        switch (mutation.type) {
            case 'UPDATE_RULES':
                ability.update(mutation.payload)
                break
            case 'CLEAR_RULES':
                ability.update([{ actions: 'read', subject: 'all' }])
                break
        }
    })
}
