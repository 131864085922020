import {Validator} from 'vee-validate';
import vv_locale_nl from 'vee-validate/dist/locale/nl'
export function extendValidatorRules(rules) {
    if (Array.isArray(rules)) {
        rules.forEach(rule => {
            if (rule.hasOwnProperty('name') && rule.hasOwnProperty('validator')) {
                Validator.extend(rule.name, rule.validator);
            }
        })
    }
}

/**
 * Extend standard nl locale to allow adding custom messages
 * @type {any}
 */
export const vv_locale_nl_extended = Object.assign({}, vv_locale_nl,
    {
    messages: {
        ...vv_locale_nl.messages,
        alpha_name: field => `${field} mag alleen letters, streepjes en spaties bevatten`,
        before_time: field => `${field} moet na de begin tijd zijn.`
    }
})
