/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

import { Ability } from "@casl/ability";
const TYPE_KEY = "__caslSubjectType__";

const getters = {
    // COMPONENT
    // vx-autosuggest
    // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
    windowBreakPoint: state => {
        // This should be same as tailwind. So, it stays in sync with tailwind utility classes
        if (state.windowWidth >= 1200) return "xl";
        else if (state.windowWidth >= 992) return "lg";
        else if (state.windowWidth >= 768) return "md";
        else if (state.windowWidth >= 576) return "sm";
        else return "xs";
    },
    getLoggedInUser: state => {
        return state.AppActiveUser.uid !== 0 ? state.AppActiveUser : false;
    },
    getSelectedAttendee: state => {
        return state.selectedAttendee;
    },
    getSelectedEvent: state => {
        return state.selectedEvent;
    },

    getTasks: state => {
        return state.tasks;
    },

    getTaskType: state => {
        return state.taskType;
    },

    getTaskOverviewPaginationMeta: state => {
        return state.taskOverviewPaginationMeta;
    },

    getTaskGroup: state => groupName => {
        return state.tasks[groupName];
    },
    getTaskFlag: state => flagName => {
        if (state.taskFlags && state.taskFlags.hasOwnProperty(flagName)) {
            return state.taskFlags[flagName];
        }
    },
    hasActiveTaskFilters: state => {
        return !!Object.getOwnPropertyNames(state.taskFilters).filter(
            prop => prop !== "__ob__"
        ).length;
    },
    getTaskFilterQueryString: state => {
        return state.taskFilterQueryString;
    },
    ability() {
        return new Ability([], {
            subjectName(subject) {
                return !subject || typeof subject === "string"
                    ? subject
                    : subject[TYPE_KEY];
            }
        });
    },
    getPlace: state => id => {
        return state.places.find(place => place.id === id);
    },
    getRoom: state => id => {
        return state.rooms.find(room => room.id === id);
    },
    getRoomsByPlaceId: state => placeId => {
        return state.rooms.filter(room => room.place_id === placeId);
    },
    getUserHasCanvasLogin: state => {
        return state.AppActiveUser.hasCanvasLogin;
    },
    getPlaces: state => {
        return state.places;
    },
    getRooms: state => {
        return state.rooms;
    },
    getUserSelectOptions: state => {
        return state.userSelectOptions;
    }
};

export default getters;
