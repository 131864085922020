/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList"
import themeConfig from "@/../themeConfig.js"
import colors from "@/../themeConfig.js"


// /////////////////////////////////////////////
// Variables
// /////////////////////////////////////////////

const userDefaults = {
  uid         : 0,          // From Auth
  displayName : "Gebruiker", // From Auth
  initials : "",
  about       : "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
  photoURL    : null, // From Auth
  location  : false,
  hasCanvasLogin: false
}

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    AppActiveUser           : userDefaults,
    bodyOverlay             : false,
    isVerticalNavMenuActive : true,
    mainLayoutType          : themeConfig.mainLayoutType || "vertical",
    navbarSearchAndPinList  : navbarSearchAndPinList,
    reduceButton            : themeConfig.sidebarCollapsed,
    verticalNavMenuWidth    : "default",
    verticalNavMenuItemsMin : false,
    scrollY                 : 0,
    starredPages            : navbarSearchAndPinList["pages"].data.filter((page) => page.is_bookmarked),
    theme                   : themeConfig.theme || "light",
    themePrimaryColor       : colors.primary,

    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth: null,

    //CASL
    rules:[],

    //OMJS
    selectedAttendee: false,
    tasks: [],
    taskType: 'active',
    taskFlags: {},
    taskTags: {},
    taskOverviewPaginationMeta: {
        from: 0,
        to: 0,
        current_page: 1,
        last_page: 0,
        per_page: 0,
        total: 0
    },
    taskFormOpened: false,
    taskFilters: {},
    taskFilterQueryString: '',
    onlyTodoFilters: false,
    noTaskFilters: false,
    attendeeNotesFormOpened: false,
    plannedTasks: false,

    places: [],
    rooms:[],

    userSelectOptions: [],
    userSelectOptionsLoadStatus: 0
}

export default state
