/**
 * Change default parameters for vuesax notification function
 */
export const extendNotify = {
    install: function (Vue, options) {

        //Wrapper function
        var notifyExtend = function (parameters) {
            const defaultParams = {
                time: 30000,
                position: 'top-center'
            }

            parameters = Object.assign(defaultParams, parameters);
            notifyOrg(parameters);
        }

        /**
         * @typedef NotiParams
         * @property {string} position
         * @property {string} color
         */

        /**
         * Close active notifications
         * optionally using parameters to narrow down which notifications to close
         *
         * @param {NotiParams} [parameters = {}] define which notifications to close
         */
        var closeNotify = function (parameters) {
            let querySelector = '.activeNoti';
            parameters = parameters || {};

            if (parameters.hasOwnProperty('position')) {
                querySelector = `.vs-noti-${parameters.position}${querySelector}`;
            }
            if (parameters.hasOwnProperty('color')) {
                querySelector = `.vs-noti-${parameters.color}${querySelector}`;
            }

            const notifications = document.querySelectorAll(querySelector);

            notifications.forEach(notification => {
                if (notification && notification.hasOwnProperty('__vue__') && notification.__vue__.hasOwnProperty('$root') && notification.__vue__.$root.hasOwnProperty('closeNoti')) {
                    notification.__vue__.$root.closeNoti();
                }
            });
        }

        //Original is declared in every beforeCreate hook
        //So we have to override in every beforeCreate hook using mixin
        var notifyOrg;
        Vue.mixin({
            beforeCreate: function beforeCreate() {
                if (this.$vs && this.$vs.notify) {
                    notifyOrg = this.$vs.notify;
                    this.$vs.notify = notifyExtend;
                    this.$vs.closeNotify = closeNotify;
                }
            }
        })
    }
};
