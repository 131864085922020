/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const actions = {

    // /////////////////////////////////////////////
    // APPLICATION
    // /////////////////////////////////////////////
    /**
     * init is run when the page is (re)loaded
     *
     * @param commit
     * @param dispatch
     * @returns {Promise<void>}
     */
    async init({ commit, dispatch }) {
        //Check if local storage has the userinfo key
        let localStorageInfo = JSON.parse(localStorage.getItem("userInfo"));

        //If it exists and the uid property is set and not 0 (default), check if a user is logged in
        if (localStorageInfo && localStorageInfo.hasOwnProperty('uid') && localStorageInfo.uid != 0) {

            //Perform a call to user, to see if a user has been authorized
            let invalid = false;
            await axios.get('/api/user').then(response => {

                //user id mismatch, not valid
                if (localStorageInfo.uid !== response.data) {
                    invalid = true;
                }
            }).catch(error => {
                //if call failed, not valid
                invalid = true;
            });

            //If no valid user is found, destroy user info and rules in local storage and return
            if (invalid) {
                dispatch('destroy');
                return;
            }
        }
        commit('UPDATE_USER_INFO', localStorageInfo || {});
        commit('UPDATE_RULES', localStorage.getItem('rules') ? JSON.parse(atob(localStorage.getItem('rules'))) : []);
    },
    destroy({commit}) {
        commit('CLEAR_USER_INFO');
        commit('CLEAR_RULES');
    },

    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////

    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////

    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },
    clearUserInfo({commit}) {
        commit('CLEAR_USER_INFO');
    },
    updateRules({commit}, payload) {
        commit('UPDATE_RULES', payload);
    },
    clearRules({commit}) {
        commit('CLEAR_RULES');
    },

    // /////////////////////////////////////////////
    // OMJS/Tasks
    // /////////////////////////////////////////////

    getTasks({commit, getters}) {
        const taskFilterQueryString = getters.getTaskFilterQueryString;
        const type = getters.getTaskType;
        axios.get(`/api/task/${type}${taskFilterQueryString}`).then(result => {
            commit('setTasks', result.data.data);
            commit('setTaskOverviewPaginationMeta', result.data.meta);
            commit('setFlags', result.data.meta.flags);
            commit('setTags', result.data.meta.tags);
        });
    },
    applyTaskFilters({dispatch}) {
        dispatch('getTaskFilterQueryString');
        dispatch('getTasks');
    },
    clearAllTaskFilters({dispatch, commit, getters}) {
        if (getters.hasActiveTaskFilters) {
            commit('setTaskFilters', {});
            dispatch('applyTaskFilters');
        }
    },
    toggleTaskFilter({dispatch, commit, state}, {filterName, filterValue, or}) {
        const taskFilters = state.taskFilters;
        let action = true;
        if (taskFilters.hasOwnProperty(filterName) && taskFilters[filterName].includes(filterValue)) {
            action = false;
        }

        if (action) {
            or.forEach(orRule => {
                const [orFilterName, orFilterValue] = orRule.split('.');
                if (state.taskFilters.hasOwnProperty(orFilterName)) {
                    if (orFilterValue === undefined || state.taskFilters[orFilterName].includes(orFilterValue)) {
                        dispatch('removeTaskFilter', {filterName: orFilterName, filterValue: orFilterValue});
                    }
                }
            });
            dispatch('addTaskFilter', {filterName, filterValue});
        } else {
            dispatch('removeTaskFilter', {filterName, filterValue});
        }
        commit('setTaskPage', 1);
        dispatch('applyTaskFilters');
    },

    addTaskFilter({commit}, {filterName, filterValue}) {
        commit('addTaskFilter', {filterName, filterValue});
    },

    removeTaskFilter({commit}, {filterName, filterValue}) {
        commit('removeTaskFilter', {filterName, filterValue});
    },

    setTaskFilters({commit, dispatch}, filters) {
        commit('setTaskFilters', filters);
        dispatch('applyTaskFilters');
    },

    switchTaskType({dispatch, commit, state}, type) {
        if (state.taskType !== type) {
            commit('setTaskType', type);
            commit('setTaskPage', 1);
            dispatch('applyTaskFilters');
        }
    },

    getTaskFilterQueryString: ({state, commit, getters}) => {
        let urlParams = new URLSearchParams();
        Object.entries(state.taskFilters).forEach(([filterName, filterValues]) => {
            if (filterValues.filter(val => val !== '__ob__').length) {
                urlParams.append(filterName, filterValues.join(','));
            }
        });

        const pagination = getters.getTaskOverviewPaginationMeta;
        if (pagination.current_page > 1) {
            urlParams.append('page', pagination.current_page);
        }

        const filterQueryString = urlParams.toString() ? `?${urlParams.toString()}`.replaceAll(/(=(&)|=$)/g, '$2') : '';
        commit('setTaskFilterQueryString', filterQueryString);
    },

    switchTaskPage: ({commit, dispatch, state}, page) => {
        if (page && state.taskOverviewPaginationMeta.current_page !== page) {
            commit('setTaskPage', page);
            dispatch('applyTaskFilters');
        }
    },

    getPlacesList({commit}) {
        this._vm.$http.get('/api/event-wizard/places').then(result => {
            commit('SET_PLACES', result.data);
        })
    },
    getRoomsList({commit}) {
        this._vm.$http.get('/api/event-wizard/rooms').then(result => {
            commit('SET_ROOMS', result.data);
        })
    },

    getUserSelect({commit, state}) {
        if (state.userSelectOptionsLoadStatus === 0) {
            commit('SET_USER_SELECT_OPTIONS_LOAD_STATUS', 1);
            this._vm.$http.get('/api/users/select').then(result => {
                commit('SET_USER_SELECT_OPTIONS', result.data);
                commit('SET_USER_SELECT_OPTIONS_LOAD_STATUS', 2);
            });
        }
    }
}

export default actions
