/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
//todo via import inladen
import {isEmpty} from "lodash";

const userDefaults = {
    uid         : 0,          // From Auth
    displayName : "Gebruiker", // From Auth
    initials : "",
    about       : "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
    photoURL    : null, // From Auth
    location    : false,
    hasCanvasLogin: false
}

const mutations = {


    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////

    // Vertical NavMenu

    TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
        state.isVerticalNavMenuActive = value
    },
    TOGGLE_REDUCE_BUTTON(state, val) {
        state.reduceButton = val
    },
    UPDATE_MAIN_LAYOUT_TYPE(state, val) {
        state.mainLayoutType = val
    },
    UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
        state.verticalNavMenuItemsMin = val
    },
    UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
        state.verticalNavMenuWidth = width
    },


    // VxAutoSuggest

    UPDATE_STARRED_PAGE(state, payload) {

        // find item index in search list state
        const index = state.navbarSearchAndPinList["pages"].data.findIndex((item) => item.url == payload.url)

        // update the main list
        state.navbarSearchAndPinList["pages"].data[index].is_bookmarked = payload.val

        // if val is true add it to starred else remove
        if (payload.val) {
            state.starredPages.push(state.navbarSearchAndPinList["pages"].data[index])
        } else {
            // find item index from starred pages
            const index = state.starredPages.findIndex((item) => item.url == payload.url)

            // remove item using index
            state.starredPages.splice(index, 1)
        }
    },

    // Navbar-Vertical

    ARRANGE_STARRED_PAGES_LIMITED(state, list) {
        const starredPagesMore = state.starredPages.slice(10)
        state.starredPages = list.concat(starredPagesMore)
    },
    ARRANGE_STARRED_PAGES_MORE(state, list) {
        let downToUp = false
        let lastItemInStarredLimited = state.starredPages[10]
        const starredPagesLimited = state.starredPages.slice(0, 10)
        state.starredPages = starredPagesLimited.concat(list)

        state.starredPages.slice(0, 10).map((i) => {
            if (list.indexOf(i) > -1) downToUp = true
        })

        if (!downToUp) {
            state.starredPages.splice(10, 0, lastItemInStarredLimited)
        }
    },


    // ////////////////////////////////////////////
    // UI
    // ////////////////////////////////////////////

    TOGGLE_CONTENT_OVERLAY(state, val) {
        state.bodyOverlay = val
    },
    UPDATE_PRIMARY_COLOR(state, val) {
        state.themePrimaryColor = val
    },
    UPDATE_THEME(state, val) {
        state.theme = val
    },
    UPDATE_WINDOW_WIDTH(state, width) {
        state.windowWidth = width
    },
    UPDATE_WINDOW_SCROLL_Y(state, val) {
        state.scrollY = val
    },


    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    // Updates user info in state and localstorage
    UPDATE_USER_INFO(state, payload) {

        // Get Data localStorage
        let userInfo = JSON.parse(localStorage.getItem("userInfo")) || state.AppActiveUser

        for (const property of Object.keys(payload)) {

            if (payload[property] != null) {
                // If some of user property is null - user default property defined in state.AppActiveUser
                state.AppActiveUser[property] = payload[property]

                // Update key in localStorage
                userInfo[property] = payload[property]
            }


        }
        // Store data in localStorage
        localStorage.setItem("userInfo", JSON.stringify(userInfo))
    },

    CLEAR_USER_INFO(state) {
        //Reset user to default user (guest)
        for (const property of Object.keys(userDefaults)) {
            state.AppActiveUser[property] = userDefaults[property];
        }
        localStorage.removeItem("userInfo");
    },

    UPDATE_RULES(state, payload) {
        state.rules = payload;
        localStorage.setItem('rules', btoa(JSON.stringify(state.rules)));
    },

    CLEAR_RULES(state) {
        state.rules = [];
        localStorage.removeItem('rules');
    },

    // /////////////////////////////////////////////
    // OMJS
    // /////////////////////////////////////////////
    setSelectedAttendee(state, id) {
        state.selectedAttendee = id;
    },
    setSelectedEvent(state, id) {
        state.selectedEvent = id;
    },
    setTasksByType(state, {type, tasks}) {
        state.tasks[type] = tasks;
    },
    initTaskType(state, {type, tasks}) {
        this._vm.$set(state.tasks, type, tasks || []);
    },
    setTasks(state, tasks) {
        state.tasks = tasks;
        this._vm.$vs.loading.close();
    },
    setTaskOverviewPaginationMeta(state, meta) {
        Object.getOwnPropertyNames(state.taskOverviewPaginationMeta).forEach(property => {
            state.taskOverviewPaginationMeta[property] = meta[property];
        });
    },
    setTaskPage(state, page) {
        state.taskOverviewPaginationMeta.current_page = page;
    },
    setFlags(state, flags) {
        if (isEmpty(state.taskFlags)) {
            state.taskFlags = flags;
        }
    },
    setTags(state, tags) {
        if (isEmpty(state.taskTags)) {
            state.taskTags = tags;
        }
    },
    setTaskFormOpen(state, open) {
        state.taskFormOpened = open;
    },
    setTaskType(state, type) {
        state.taskType = type;
    },
    setTaskFilter(state, {filterName, filterValues}) {
        this._vm.$set(state.taskFilters, filterName, filterValues);
    },
    addTaskFilter(state, {filterName, filterValue}) {
        if (state.taskFilters.hasOwnProperty(filterName)) {
            state.taskFilters[filterName].push(filterValue);
        } else {
            this._vm.$set(state.taskFilters, filterName, [filterValue]);
        }
    },
    removeTaskFilter(state, {filterName, filterValue}) {
        if (filterValue !== undefined) {
            const valueIndex = state.taskFilters[filterName].indexOf(filterValue);
            state.taskFilters[filterName].splice(valueIndex, 1);
        } else {
            this._vm.$delete(state.taskFilters, filterName);
        }

        if (state.taskFilters.hasOwnProperty(filterName) && !state.taskFilters[filterName].length) {
            this._vm.$delete(state.taskFilters, filterName);
        }
    },
    setTaskFilters(state, filters) {
        this._vm.$set(state, 'taskFilters', filters);
    },
    setTaskFilterQueryString(state, queryString) {
        state.taskFilterQueryString = queryString;
    },
    setOnlyTodoFilters(state, onlyTodo) {
        state.onlyTodoFilters = onlyTodo;
    },
    setAttendeeNotesFormOpen(state, open) {
        state.attendeeNotesFormOpened = open;
    },
    SET_PLACES(state, places) {
        state.places = places;
    },
    SET_ROOMS(state, rooms) {
        state.rooms = rooms;
    },
    SET_USER_SELECT_OPTIONS(state, userSelectOptions) {
        state.userSelectOptions = userSelectOptions;
    },
    SET_USER_SELECT_OPTIONS_LOAD_STATUS(state, loadStatus) {
        state.userSelectOptionsLoadStatus = loadStatus;
    }
}

export default mutations

