import Vue from "vue";
import moment from "moment";
import 'moment/locale/nl';
import _ from 'lodash';
import {subject} from "@casl/ability";

moment.locale('nl');

Vue.filter(
    'toCurrency', function (value) {
        if (typeof value !== "number") {
            return value;
        }
        var formatter = new Intl.NumberFormat('nl', {
            style: 'currency',
            currency: 'EUR'
        });
        return formatter.format(value);
    }
);

Vue.filter(
    'formatDate', function (value, toFormat, fromFormat) {
        toFormat = toFormat || 'DD-MM-YYYY';
        fromFormat = fromFormat || 'YYYY-MM-DD HH:mm:ss'
        return moment(String(value), fromFormat).format(toFormat);
    }
);

Vue.filter(
    'formatTime', function (value, format) {
        format = format || 'HH:mm';
        return moment(String(value), 'HH:mm:ss').format(format);
    }
);

Vue.filter(
    'formatTelephone', function (value, prefix) {
        prefix = prefix || '';

        return prefix + (String(value).match(/^0+$/) ? '-' : value);
    }
)

Vue.filter(
    'ucFirst', function(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
)

Vue.filter(
    'uc', function(value) {
        return value.toUpperCase();
    }
)

Vue.filter(
    'lc', function(value) {
        return value.toLowerCase();
    }
)

Vue.filter(
    'labelRequired', function(value) {
        return value + " *";
    }
)

//todo why not multiple filters possible?? Paul 22 04 2020 1609
Vue.mixin({
    methods: {
        first_letter_uppercase(value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        ucFirst(value) {
            return this.first_letter_uppercase(value);
        }
    }
});

Vue.mixin({
    methods: {
        userFullName: function(user) {
            if (user.hasOwnProperty('first_name') && user.hasOwnProperty('middle_name') && user.hasOwnProperty('last_name')) {
                let fullName = user.first_name;
                if (user.middle_name) {
                    fullName += " " + user.middle_name;
                }
                fullName += " " + user.last_name;
                return fullName;
            } else {
                return false;
            }
        },
        moment: function(...args) {
            return moment(...args)
        },
        objectArrayUniqueProperty: function(array, uniquePropertyPath) {
            let newArray = [];
            let map = new Map();
            for (let item of array) {
                let propertyValue = _.get(item, uniquePropertyPath);
                if (!map.has(propertyValue)) {
                    map.set(propertyValue, true);
                    newArray.push(item);
                }
            }

            return newArray;
        },
        subject
    }
})
